// getFormattedLiveStreamViewers.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';

import getLiveStreamData from '../selector/getLiveStreamData.js';
import numberWithCommas from '../resource/numberWithCommas.js';
import { PRESET_PREVIEW, PRESET_SD } from '../resource/liveStreamConstants.js';

/**
 * Select formatted live stream viewers by live stream
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} streamId - stream id.
 * @return {string} will return '-' if viewers is 0, will return viewers amount with comma if viewers is more than 0.
 */
const getFormattedLiveStreamViewers = createCachedSelector(
  (state, streamId) =>
    getLiveStreamData(state, [streamId, 'presets', PRESET_PREVIEW], 'viewers'),
  (state, streamId) =>
    getLiveStreamData(state, [streamId, 'presets', PRESET_SD], 'viewers'),
  (previewViewers, sdViewers) => {
    const viewers = previewViewers + sdViewers;
    return !Number.isNaN(viewers) ? numberWithCommas({ number: viewers }) : '-';
  }
)((state, streamId) => `${streamId}`);

export default getFormattedLiveStreamViewers;
