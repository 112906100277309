// LiveStreamCard.js
import { connect } from 'react-redux';

import getUsername from '../selector/getUsername.js';
import getUserData from '../selector/getUserData.js';
import getFormattedLiveStreamViewers from '../selector/getFormattedLiveStreamViewers.js';
import getMeData from '../selector/getMeData.js';
import getLiveStreamLanguageBadges from '../selector/getLiveStreamLanguageBadges.js';
import getUserBadges from '../selector/getUserBadges.js';
import getLiveStreamData from '../selector/getLiveStreamData.js';

import fetchUser from '../action/fetchUser.js';
import fetchLiveStreamAvailability from '../action/fetchLiveStreamAvailability.js';
import fetchTranslationByKey from '../action/fetchTranslationByKey.js';
import addInViewportUserId from '../action/addInViewportUserId.js';
import removeInViewportUserId from '../action/removeInViewportUserId.js';
import { push } from '../action/navigationAction.js';

import LiveStreamCard from '../component/LiveStreamCard.jsx';
import i18n from '../resource/i18n.js';
import getCurrentLanguage from '../resource/getCurrentLanguage.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getPrivateShowStatus from '../selector/getPrivateShowStatus.js';
import getOperationData from '../selector/getOperationData.js';
import { PUBLIC_BADGE_URL_PATH, LIVESTREAM_FEED } from '../RemoteConfigKeys.js';

const mapStateToProps = (state, { userId, streamId }) => {
  const username = getUserData(state, userId, 'username');
  const formattedViewers = getFormattedLiveStreamViewers(state, streamId);
  const meId = getMeData(state, 'id');
  const isAuthed = !!meId;
  const [location] = getUserBadges(state, userId, 'locations');
  const locationKey = location?.replace?.(':', '_') || '';
  const isPusherChannelDataPolledOrAvailabilityChecked = getLiveStreamData(
    state,
    [userId],
    'isPusherChannelDataPolledOrAvailabilityChecked'
  );
  const fetchLiveStreamAvailabilityId = getLiveStreamData(
    state,
    [userId],
    'fetchLiveStreamAvailabilityId'
  );
  const isLiveStreaming = getLiveStreamData(state, [userId], 'isLiveStreaming');
  return {
    meId,
    username,
    displayedUsername: getUsername(state, userId, 'displayNameFirst'),
    formattedViewers,
    isAuthed,
    languageBadges: getLiveStreamLanguageBadges(
      state,
      streamId,
      getCurrentLanguage()
    ),
    locationKey,
    isLocationKeyTranslationFetched: getNetworkingData(
      state,
      ['translation', locationKey, i18n.language],
      'isFetched'
    ),
    fetchLiveStreamAvailabilityId,
    isLiveStreaming: isPusherChannelDataPolledOrAvailabilityChecked
      ? Boolean(isLiveStreaming)
      : isLiveStreaming,
    isKaraokeDeviceOnline: !!getLiveStreamData(state, [streamId], 'device'),
    publicBadgeUrlPath: getRemoteConfigData(state, PUBLIC_BADGE_URL_PATH),
    liveStreamFeed: getRemoteConfigData(state, LIVESTREAM_FEED),
    streamStatus: getLiveStreamData(state, [streamId], 'status'),
    privateShowStatus: getPrivateShowStatus(state, streamId),
    privateUserChannel: getOperationData(
      state,
      ['pusher', 'channels'],
      `private-enc-user@${userId}`
    ),
    badges: getUserData(state, userId, 'livestreamCardBadges'),
    countries: getUserBadges(state, userId, 'countries'),
  };
};

const mapDispatchToProps = (dispatch, { userId }) => {
  return {
    push: ({ pathname, state }) => dispatch(push(pathname, state)),
    fetchUser: () => dispatch(fetchUser({ id: userId })),
    fetchLiveStreamAvailability: ({ fetchLiveStreamAvailabilityId }) =>
      dispatch(
        fetchLiveStreamAvailability({ fetchLiveStreamAvailabilityId, userId })
      ),
    fetchTranslationByKey: ({ key }) =>
      dispatch(fetchTranslationByKey({ key })),
    addInViewportUserId: ({ id }) => dispatch(addInViewportUserId({ id })),
    removeInViewportUserId: ({ id }) =>
      dispatch(removeInViewportUserId({ id })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveStreamCard);
