// fetchTranslationByKey.js
'use strict';
import fetch from '../resource/customFetch.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';
import i18n, { localeConvertor } from '../resource/i18n.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import {
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

const THROTTLE_MSEC = 60 * 1000;

/**
 * Fetch translation by key
 * @kind action
 * @param {string} {key} - i18n key.
 * @param {array} {groups} - lokalise key groups.
 * @return {Promise} Action promise.
 */
const fetchTranslationByKey =
  ({ key, groups = [] } = {}) =>
  async (dispatch, getState) => {
    const selectPath = ['translation', key || groups.join(','), i18n.language];

    // Don't fetch same key again in one minute.
    const isFetching = getNetworkingData(getState(), selectPath, 'isFetching');
    const fetchedTimestamp =
      getNetworkingData(getState(), selectPath, 'fetchedTimestamp') || 0;
    if (isFetching || fetchedTimestamp + THROTTLE_MSEC > Date.now()) {
      return { type: '' };
    }

    const fetchOptions = {
      method: 'GET',
      headers: {
        ...getHeaders(),
      },
    };

    const url = getResourceUrl({ endpoint: '/translations' });
    const lang = localeConvertor({
      locale: i18n.language,
      isISO639: true,
    });
    url.searchParams.set('lang', lang);
    if (key) {
      url.searchParams.append('key', key);
    }
    groups.forEach(group => url.searchParams.append('group', group));
    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });
    try {
      const response = await fetch(url.href, fetchOptions);

      if (!response.ok) {
        await handleFetchError({ response });
      }

      const data = await response.json();
      i18n.addResources(i18n.language, 'translation', data);
      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default fetchTranslationByKey;
