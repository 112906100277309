// fetchLiveStreamAvailability.js
'use strict';
import fetch from '../resource/customFetch.js';
import getMeData from '../selector/getMeData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import handleFetchError from '../resource/handleFetchError.js';

import {
  MERGE_LIVE_STREAM_DATA,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';
import { LIVESTREAM_AVAILABILITY_CHECK_TIMEOUT_MSEC } from '../RemoteConfigKeys.js';

/**
 * Fetch the availability of liveStream
 * @kind action
 * @param {object} payload - action payload.
 * @param {string} payload.fetchLiveStreamAvailabilityId - the id of individual action.
 * @param {string} payload.userId - user id.
 * @return {Promise} Action promise.
 */
const fetchLiveStreamAvailability =
  ({ fetchLiveStreamAvailabilityId, userId } = {}) =>
  async (dispatch, getState) => {
    const selectPath = ['liveStream', 'availability'];
    const token = getMeData(getState(), 'token');
    const timeoutInMsec = getRemoteConfigData(
      getState(),
      LIVESTREAM_AVAILABILITY_CHECK_TIMEOUT_MSEC
    );
    const fetchOptions = {
      method: 'HEAD',
      headers: {
        ...getHeaders(),
        'Content-Type': 'application/json',
      },
    };

    if (token) {
      fetchOptions.headers.Authorization = `Bearer ${token}`;
    }

    let timer;
    const url = getResourceUrl({ endpoint: `/users/${userId}/session` });
    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });
    try {
      const promiseTimer = new Promise(resolve => {
        clearTimeout(timer);
        timer = setTimeout(() => resolve('timeout'), timeoutInMsec);
      });
      const promiseFetch = fetch(url.href, fetchOptions);

      let response = await Promise.race([promiseTimer, promiseFetch]);
      if (!response || response === 'timeout') {
        dispatch({
          type: MERGE_LIVE_STREAM_DATA,
          payload: {
            selectPath: [userId],
            data: {
              fetchLiveStreamAvailabilityId,
              isPusherChannelDataPolledOrAvailabilityChecked: true,
              isLiveStreaming: false,
            },
          },
        });
        return dispatch({
          type: SET_NETWORKING_ERROR,
          payload: { selectPath, error: new Error('timeout') },
        });
      }

      clearTimeout(timer);
      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl: url,
        });
      }

      dispatch({
        type: MERGE_LIVE_STREAM_DATA,
        payload: {
          selectPath: [userId],
          data: {
            fetchLiveStreamAvailabilityId,
            isPusherChannelDataPolledOrAvailabilityChecked: true,
            isLiveStreaming: true,
          },
        },
      });
      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      dispatch({
        type: MERGE_LIVE_STREAM_DATA,
        payload: {
          selectPath: [userId],
          data: {
            fetchLiveStreamAvailabilityId,
            isPusherChannelDataPolledOrAvailabilityChecked: true,
            isLiveStreaming: false,
          },
        },
      });
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default fetchLiveStreamAvailability;
