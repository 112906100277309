// numberWithCommas.js
'use strict';

// Modified by https://stackoverflow.com/a/2901298
const numberWithCommas = ({ number }) => {
  if (isNaN(number) || number === null) return null;

  const numberString = number.toString();
  if (numberString.includes('.')) {
    const [integerString, decimalString = ''] = numberString.split('.');
    const integerStringWithComma = integerString.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    );
    return [integerStringWithComma, decimalString].join('.');
  }
  return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export default numberWithCommas;
