// getLiveStreamLanguageBadges.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';

import getUserBadges from '../selector/getUserBadges.js';

const MAXIMUM_BADGE_AMOUNT = 2; // TODO: remote config

const emptyList = [];

/**
 * Select live stream language badges by stream id
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} streamId - stream id.
 * @param {string} currentLanguage - current language.
 * @return {array} The live stream language badges.
 */
const getLiveStreamLanguageBadges = createCachedSelector(
  (state, streamId) => getUserBadges(state, streamId, 'langs'),
  (state, streamId, currentLanguage) => currentLanguage,
  (languageBadges = emptyList, currentLanguage) => {
    const badgePrefix = 'lang:';
    const languages = languageBadges
      .map(badge => badge?.replace(badgePrefix, ''))
      .filter(language => language);
    const result = [
      ...languages.filter(language => currentLanguage.includes(language)),
      ...languages.filter(language => !currentLanguage.includes(language)),
    ]
      .slice(0, MAXIMUM_BADGE_AMOUNT)
      .map(language => `${badgePrefix}${language}`);

    return result;
  }
)((state, streamId, currentLanguage) => `${streamId}:${currentLanguage}`);

export default getLiveStreamLanguageBadges;
